<script setup lang="ts">
import { LazyTextHeadersH1, LazyTextHeadersH2 } from "#components";
import type { PropType } from "vue";
import type {
  IEditorJsBlockHeader,
  TEditorJsBlock,
} from "~/composable/pageData.types";

defineProps({
  data: {
    type: Object as PropType<TEditorJsBlock<IEditorJsBlockHeader>>,
    required: true,
  },
});

const component = {
  h1: LazyTextHeadersH1,
  h2: LazyTextHeadersH2,
};
</script>

<template>
  <component :is="component[`h${data.data.level}`]" :text="data.data.text" />
</template>

<style scoped></style>
